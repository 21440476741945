<template>
  <el-card v-if="showMarketplaceProfileBanner"
    shadow="never"
    class="mb-1">
    <div class="d-flex">
      <i class="fas fa-exclamation-triangle color-warning mr-1 fs-2-5rem"
        aria-hidden="true" />

      <div>
        <h4>{{ $t('marketplace.profile') }}</h4>
        <p v-if="!hasProfile">
          {{ $t('marketplace.not-ready-publishing') }}
        </p>

        <div v-if="hasProfile && !activeProfile"
          class="new-profile-prompt">
          <p>{{ $t('marketplace.currently-private') }}</p>
          <p v-if="canEditProfile">
            {{ $t('marketplace.easy-get-connected') }}
          </p>
        </div>
      </div>
    </div>

    <div class="text-align-right mt-1">
      <el-dropdown trigger="click"
        placement="bottom"
        @command="handleHideCommand">
        <el-button name="hide-message">
          {{ $t('marketplace.hide-message') }}
        </el-button>

        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item command="day">
              {{ $t('marketplace.hide-today') }}
            </el-dropdown-item>
            <el-dropdown-item command="week">
              {{ $t('marketplace.hide-week') }}
            </el-dropdown-item>
            <el-dropdown-item command="forever">
              {{ $t('marketplace.hide-forever') }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>

      <el-button v-if="showMarketplaceProfile"
        type="primary"
        class="ml-0-5"
        name="edit-profile"
        data-name="btn-create-profile"
        @click="$router.push('/marketplace/profile', () => {})">
        {{ hasProfile ? $t('marketplace.edit-profile') : $t('marketplace.create-profile') }}
      </el-button>
    </div>
  </el-card>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { DateTime } from 'luxon'
import sdk from '@megaport/api-sdk'

export default {
  name: 'MarketplaceProfileWarning',

  inject: ['isFeatureEnabled'],

  data() {
    return {
      hideUntilDate: null,
    }
  },

  computed: {
    ...mapState('Marketplace', ['marketplaceData']),
    ...mapGetters('Auth', ['hasAuth', 'isManagedAccount']),
    ...mapGetters('Company', ['companyUid']),

    hasProfile() {
      return !!this.marketplaceData.find(mp => this.companyUid === mp.companyUid)
    },
    activeProfile() {
      const profile = this.marketplaceData.find(mp => this.companyUid === mp.companyUid)
      return profile ? profile.active : false
    },
    canEditProfile() {
      return this.hasAuth('company_admin')
    },
    showMarketplaceProfile() {
      return !this.hasProfile && !this.isManagedAccount || (this.hasProfile && !this.activeProfile && this.canEditProfile)
    },
    showMarketplaceProfileBanner() {
      return this.isFeatureEnabled('MARKETPLACE_PROFILE_ENABLED') && !this.hideUntilDate && this.showMarketplaceProfile
    },
  },

  created() {
    const lsValue = localStorage.getItem(`_mpMarketplaceBanner_${sdk.instance.credentials.personUid}`)

    if (lsValue) {
      const date = DateTime.fromISO(lsValue)

      if (!date.isValid) {
        this.hideUntilDate = null
      } else if (date < DateTime.now()) {
        this.hideUntilDate = null
      } else {
        this.hideUntilDate = date
      }
    }
  },

  methods: {
    /**
     * Handles hiding the marketplace profile message for a given amount of time.
     * @param {string} duration The length of time to hide the message for (day, week or forever).
     */
    handleHideCommand(duration) {
      const now = DateTime.now()
      const props = {
        title: this.$t('marketplace.profile-message-hidden'),
        type: 'success',
        duration: 3000,
      }

      switch (duration) {
        case 'day':
          this.hideUntilDate = now.plus({ days: 1 }).startOf('day')
          props.message = this.$t('marketplace.hidden-day')
          this.$notify(props)
          break
        case 'week':
          this.hideUntilDate = now.plus({ days: 7 }).startOf('day')
          props.message = this.$t('marketplace.hidden-week')
          this.$notify(props)
          break
        case 'forever':
          if (this.canEditProfile) {
            this.$confirm(
              this.$t('marketplace.hidden-message'),
              this.$t('marketplace.hide-profile'),
              { type: 'warning' }
            )
              .then(() => {
                this.hideUntilDate = now.plus({ years: 100 })
              })
              .catch(() => { /* empty function is intentional */ })
          } else {
            this.hideUntilDate = now.plus({ years: 100 })
            props.message = this.$t('marketplace.hidden-permanently')
            this.$notify(props)
          }
          break
        default:
          console.error(duration, 'Unsupported length of time!')
          break
      }

      localStorage.setItem(`_mpMarketplaceBanner_${sdk.instance.credentials.personUid}`, this.hideUntilDate)
    },
  },
}
</script>

<style lang="scss" scoped>
.fs-2-5rem {
  font-size: 2.5rem;
}

.mt-0-5em {
  margin-top: 0.5em;
}

p {
  margin: 0;
}
</style>
