<template>
  <section>
    <!-- Provider Type Selection Modal -->
    <el-dialog :visible.sync="showProviderTypeSelection"
      :title="$t('marketplace.filter')">
      <p class="mb-1-5">
        {{ $t('marketplace.select-types') }}
      </p>

      <el-tree ref="providerListTree"
        :data="providerList"
        show-checkbox
        expand-on-click-node
        :default-expanded-keys="providerListExpandedKeys"
        :default-checked-keys="providerListCheckedKeys"
        node-key="nodeId"
        :props="{ label: 'description', children: 'serviceTypes' }"
        @check="providerListCheckChanged" />

      <template #footer>
        <el-button @click="clearProviderTypeFilter">
          {{ $t('marketplace.clear-filter') }}
        </el-button>
        <el-button type="primary"
          @click="showProviderTypeSelection = false">
          {{ $t('general.done') }}
        </el-button>
      </template>
    </el-dialog>

    <div class="p-20px">
      <!-- Marketplace Private Warning Message -->
      <marketplace-profile-warning />

      <!-- Marketplace Search Filters -->
      <el-card shadow="never"
        class="mb-1">
        <el-form :inline="true">
          <div class="d-flex">
            <mu-mega-icon icon="MarketplaceLabel"
              width="200"
              height="70" />

            <div class="flex-row-centered ml-auto">
              <!-- Sort -->
              <el-form-item :label="$t('general.sort')">
                <el-select v-model="sortOrder"
                  class="width-175px">
                  <el-option value="alpha"
                    :label="$t('marketplace.sort-alpha')" />
                  <el-option value="location"
                    :label="$t('marketplace.sort-location-count')" />
                  <el-option value="ports"
                    :label="$t('marketplace.destination-count')" />
                </el-select>
              </el-form-item>

              <!-- View Type -->
              <el-form-item>
                <el-radio-group v-model="viewType"
                  name="viewType">
                  <el-radio-button class="inverse-padding"
                    label="box">
                    <el-tooltip placement="top"
                      :content="$t('marketplace.grid-display')"
                      :open-delay="500">
                      <i class="fas fa-th"
                        aria-hidden="true"
                        data-testid="box-view" />
                    </el-tooltip>
                  </el-radio-button>
                  <el-radio-button label="list"
                    class="inverse-padding">
                    <el-tooltip placement="top"
                      :content="$t('marketplace.list-display')"
                      :open-delay="500">
                      <i class="fas fa-th-list"
                        aria-hidden="true"
                        data-testid="list-view" />
                    </el-tooltip>
                  </el-radio-button>
                </el-radio-group>
              </el-form-item>
            </div>
          </div>
        </el-form>
        <hr class="mb-1-5">

        <el-form label-width="180px">
          <div class="bs-border-box d-flex flex-wrap">
            <div class="filter-column">
              <div>
                <!-- Company Name -->
                <el-form-item :label="$t('general.company-name')"
                  class="filter-item">
                  <el-input v-model="filterText"
                    :placeholder="$t('marketplace.company-name-filter')"
                    suffix-icon="fal fa-filter" />
                </el-form-item>

                <!-- Rate Limit -->
                <el-form-item :label="$t('services.rate-limit')">
                  <el-select v-model="filterSpeed"
                    name="speedFilter"
                    multiple
                    :placeholder="$t('marketplace.select-speeds')"
                    class="full-width">
                    <el-option v-for="speed in speedList"
                      :key="speed"
                      :data-speed="speed"
                      :label="$t('general.speed-gbps', { speed })"
                      :value="speed" />
                  </el-select>
                </el-form-item>
              </div>
            </div>

            <div class="filter-column">
              <div>
                <!-- Provider Type -->
                <el-form-item v-if="providerList.length > 0 || loadingProviderTypes"
                  :label="$tc('marketplace.pluralize-provider-type', 1)">
                  <div class="el-select full-width"
                    @click.stop="$refs.providerTypeInput.focus(); setProviderTypeState(!showProviderTypeSelection)"
                    @keydown.tab="setProviderTypeState(false)"
                    @keydown.enter.prevent="setProviderTypeState(true)">
                    <div ref="providerTypeTags"
                      class="el-select__tags"
                      :style="{ 'max-width': providerTypeInputWidth - 32 + 'px' }">
                      <template v-if="!loadingProviderTypes">
                        <el-tag v-for="provider in filterProviders"
                          :key="provider.id"
                          type="info"
                          size="small"
                          class="d-block">
                          {{ tagForProvider(provider) }}
                        </el-tag>
                      </template>
                    </div>
                    <el-input ref="providerTypeInput"
                      v-loading="loadingProviderTypes"
                      :placeholder="(filterProviders.length === 0 && providerList.length > 0) ? $t('marketplace.select-provider-types') : ''"
                      suffix-icon="fal fa-pencil"
                      data-testid="provider-type-input" />
                  </div>
                </el-form-item>

                <!-- Country -->
                <el-form-item v-if="countryList.length > 0"
                  :label="$t('connections.country')"
                  class="capitalize-text">
                  <el-select v-model="filterCountry"
                    name="countryFilter"
                    multiple
                    :placeholder="$t('marketplace.select-countries')"
                    class="full-width">
                    <el-option v-for="country in countryList"
                      :key="country"
                      :label="country"
                      :data-country="country"
                      :value="country" />
                  </el-select>
                </el-form-item>
              </div>
            </div>
          </div>
        </el-form>
      </el-card>

      <div v-if="marketplaceFilter.length === 0"
        class="empty-text">
        <i class="fas fa-empty-set"
          aria-hidden="true" />
        <p>{{ $t('connections.no-matching-providers') }}</p>
      </div>

      <div v-else
        :class="{ 'grid-container': viewType === 'box' }">
        <!-- Box View Type Card -->
        <template v-if="viewType === 'box'">
          <box-view-card v-for="profile in marketplaceFilter"
            :key="profile.companyUid"
            :profile="profile" />
        </template>

        <!-- List View Type Card -->
        <list-view-table v-else
          :profiles="marketplaceFilter" />
      </div>
    </div>
  </section>
</template>

<script>
// External tools
import { mapGetters, mapState } from 'vuex'
import sdk from '@megaport/api-sdk'
// Internal tools
import captureSentryError from '@/utils/CaptureSentryError.js'
import { setFallbackImage } from '@/utils/fallbackImage'
import { slug } from '@/helpers.js'
// Components
import MarketplaceProfileWarning from '@/components/marketplace/MarketplaceProfileWarning.vue'
import BoxViewCard from '@/components/marketplace/BoxViewCard.vue'
import ListViewTable from '@/components/marketplace/ListViewTable.vue'

export default {
  name: 'MpMarketplace',

  components: {
    'marketplace-profile-warning': MarketplaceProfileWarning,
    'box-view-card': BoxViewCard,
    'list-view-table': ListViewTable,
  },

  data() {
    return {
      filterText: '',
      filterSpeed: [],
      filterProviders: [],
      filterServices: [],
      filterCountry: [],
      sortOrder: 'alpha',
      viewType: 'box',
      providerTypes: [],
      showProviderTypeSelection: false,
      loadingProviderTypes: true,
      providerTypeInputWidth: 0,
    }
  },

  computed: {
    ...mapState('Marketplace', ['marketplaceData']),
    ...mapGetters('Services', ['findPort']),

    preparingMarketplace() {
      return (
        this.marketplaceProfiles.length === 0 &&
        this.filterText.length === 0 &&
        this.filterSpeed.length === 0 &&
        this.filterProviders.length === 0 &&
        this.filterCountry.length === 0 &&
        this.filterServices.length === 0
      )
    },
    countryList() {
      const countries = []
      this.marketplaceData.forEach(profile => {
        Object.keys(profile.services).forEach(key => {
          const portInfo = this.findPort(key)
          if (portInfo?._location?.country) {
            countries.push(portInfo._location.country)
          }
        })
      })
      return countries.unique().sort()
    },
    speedList() {
      const speeds = []
      this.marketplaceData.forEach(profile => {
        Object.keys(profile.services).forEach(key => {
          const portInfo = this.findPort(key)
          if (portInfo && !isNaN(portInfo.speed)) {
            speeds.push(portInfo.speed / 1000)
          }
        })
      })
      return speeds.unique().sort()
    },
    providerList() {
      const provider = []
      // Look through all the profiles within the marketplace and add the Ids
      // of the provider types they provide. This gives us a list of all the
      // provider types that are actually in use in the marketplace now.
      for (const profile of this.marketplaceData) {
        profile.providerTypes.forEach(p => {
          provider.push(p.id)
        })
      }
      // Now filter our list of all available provider types to only include the
      // ones that are actually available through the marketplace
      return this.providerTypes.filter(pt => provider.includes(pt.id))
    },
    marketplaceProfiles() {
      // console.debug('COMPUTED', 'marketplaceProfiles')
      return this.marketplaceData
        .map(profile => {
          const obj = Object.assign({}, profile)
          const locationCount = []
          obj.ports = Object.keys(obj.services)
            .map(key => {
              const portInfo = this.findPort(key)
              if (portInfo && portInfo.locationId) {
                locationCount.push(portInfo.locationId)
              }
              return Object.assign({}, obj.services[key], {
                portInfo: portInfo,
              })
            })
            .filter(port => {
              // Only include deployed ports
              return port.portInfo?.locationId &&
                port.portInfo.provisioningStatus !== this.G_PROVISIONING_DEPLOYABLE
            })
          obj.locationCount = locationCount.unique().length
          obj.portCount = obj.ports.length
          obj.link = `/marketplace/${slug(profile.companyName)}`
          return obj
        })
        .filter(profile => profile.locationCount !== 0 && profile.portCount !== 0)
    },
    marketplaceFilter() {
      // console.debug('COMPUTED', 'marketplaceFilter')
      return this.marketplaceProfiles
        .filter(profile => {
          if (!profile.active) return false

          let matchText = true
          if (this.filterText.length > 0) {
            matchText = false
            if (profile.companyName.toLowerCase().includes(this.filterText.toLowerCase())) {
              matchText = true
            }
          }

          let matchCountry = true
          if (this.filterCountry.length > 0 && matchText) {
            matchCountry = false
            const hasCountry = profile.ports.find(port => {
              if (this.filterCountry.includes(port.portInfo._location?.country)) return true
              return false
            })
            if (hasCountry) {
              matchCountry = true
            }
          }

          let matchSpeed = true
          if (this.filterSpeed.length > 0 && matchText && matchCountry) {
            matchSpeed = false
            const hasSpeed = profile.ports.find(port => {
              if (this.filterSpeed.includes((port.portInfo.speed || port.portInfo.portSpeed) / 1000)) return true
              return false
            })
            if (hasSpeed) {
              matchSpeed = true
            }
          }

          let matchProvider = true
          if (this.filterProviders.length > 0 && matchText && matchCountry && matchSpeed) {
            matchProvider = false
            const hasProvider = profile.providerTypes.find(type => {
              if (this.filterProviders.find(p => p.id === type.id)) return true
              return false
            })
            if (hasProvider) {
              matchProvider = true
            }
          }

          let matchService = true
          if (this.filterServices.length > 0 && matchText && matchCountry && matchSpeed && matchProvider) {
            // Build a list of services to filter by that includes all the services of the filterProviders
            // unless any of the child services are in the filterServices list. This is to work around the
            // stupid artificial separation of the services from their parent providers.

            // Start with the already existing filter services
            const allFilterServices = this.filterServices.slice()
            for (const provider of this.filterProviders) {
              let foundService = false
              for (const service of provider.serviceTypes) {
                if (this.filterServices.find(s => s.id === service.id)) {
                  foundService = true
                  break
                }
              }
              // Wasn't found, so add all the others from the service types
              if (!foundService) {
                allFilterServices.push.apply(allFilterServices, provider.serviceTypes)
              }
            }
            matchService = false
            const hasService = profile.ports.find(port => {
              let f = false
              port.serviceTypes.forEach(st => {
                if (allFilterServices.find(s => s.id === st.id)) {
                  f = true // found a match
                }
              })
              if (f) return true
              return false
            })
            if (hasService) {
              matchService = true
            }
          }

          if (matchCountry && matchSpeed && matchProvider && matchText && matchService) return true
          return false
        })
        .sort((a, b) => {
          switch (this.sortOrder) {
            case 'alpha':
              if (a.companyName.toLowerCase().replace(/\s/gi, '') > b.companyName.toLowerCase().replace(/\s/gi, '')) return 1
              if (a.companyName.toLowerCase().replace(/\s/gi, '') < b.companyName.toLowerCase().replace(/\s/gi, '')) return -1
              return 0
            case 'location':
              if (a.locationCount < b.locationCount) return 1
              if (a.locationCount > b.locationCount) return -1
              return 0
            case 'ports':
              if (a.portCount < b.portCount) return 1
              if (a.portCount > b.portCount) return -1
              return 0
          }
        })
    },
    providerListExpandedKeys() {
      // Objective: expand any of the items where the parent filter was turned on.
      const expanded = []

      for (const fProvider of this.filterProviders) {
        // Just in case any of the items have changed, we will make sure they are in the current list.
        const selectedProvider = this.providerList.find(provider => {
          return provider.id === fProvider.id
        })
        if (selectedProvider) {
          expanded.push(selectedProvider.nodeId)
        }
      }

      return expanded
    },
    providerListCheckedKeys() {
      // Objective: handle any of the following situations:
      //  1. filterProviders contains the parent filter option, and none of its serviceTypes are in filterServices
      //     => all associated serviceTypes are checked as well as the parent (parent is automatically checked in
      //        tree if the children are anyway)
      //  2. filterProviders contains the parent filter option, and some but not all of its serviceTypes are in filterServices
      //     => all associated serviceTypes are checked but the parent isn't
      const checkedItems = []

      // To adhere to the way the filters were previously saved, we expect the filterProviders information
      // to be present either with 0 or some of its service types listed in the filterServices list. This
      // seems a crazy an inefficient way to go about things, but we'll stick to it for the moment since the
      // rest of the code relies on it. TODO: rewrite to be more sensible.
      for (const fProvider of this.filterProviders) {
        // Just in case any of the items have changed, we will make sure they are in the current list.
        const selectedProvider = this.providerList.find(provider => {
          return provider.id === fProvider.id
        })
        if (selectedProvider) {
          // Now see if any of the children serviceTypes have been selected.
          const selectedServices = []
          for (const service of selectedProvider.serviceTypes) {
            const fService = this.filterServices.find(s => {
              return s.id === service.id
            })
            if (fService) {
              selectedServices.push(service)
              checkedItems.push(service.nodeId)
            }
          }
          if (selectedServices.length === 0) {
            if (selectedProvider.serviceTypes.length === 0) {
              checkedItems.push(selectedProvider.nodeId)
            } else {
              for (const service of selectedProvider.serviceTypes) {
                checkedItems.push(service.nodeId)
              }
            }
          }
        }
      }

      return checkedItems
    },
  },

  watch: {
    filterText() {
      this.saveSearchParams()
    },
    filterSpeed() {
      this.saveSearchParams()
    },
    filterProviders(newVal, oldVal) {
      this.saveSearchParams()
      // If it may be taking up less height, wait until the
      // display and any animations have finished and then
      // double check and adjust the height
      if (newVal.length < oldVal.length) {
        setTimeout(() => {
          this.resetProviderListInputHeight()
        }, 500)
      } else {
        this.resetProviderListInputHeight()
      }
    },
    filterServices(newVal, oldVal) {
      this.saveSearchParams()
      // If it may be taking up less height, wait until the
      // display and any animations have finished and then
      // double check and adjust the height
      if (newVal.length < oldVal.length) {
        setTimeout(() => {
          this.resetProviderListInputHeight()
        }, 500)
      } else {
        this.resetProviderListInputHeight()
      }
    },
    filterCountry() {
      this.saveSearchParams()
    },
    sortOrder() {
      this.saveSearchParams()
    },
    viewType() {
      this.saveSearchParams()
    },
    marketplaceData() {
      this.resetProviderListInputHeight()
    },
  },

  created() {
    this.$store.dispatch('Marketplace/getMarketplaceProfile')
    sdk.instance
      .marketplace()
      .providerTypes()
      .then(types => {
        // Add globally unique node ids for use in the tree selection process.
        for (const pType of types) {
          const nodeId = pType.id
          pType.nodeId = nodeId.toString()
          for (const sType of pType.serviceTypes) {
            sType.nodeId = `${pType.nodeId}-${sType.id}`
          }
        }
        this.providerTypes = types
        this.loadingProviderTypes = false
      })
      .catch(e => {
        captureSentryError(e)
      })
  },

  mounted() {
    this.loadInitialSearch()
    this.$nextTick(() => {
      if (this.$refs.providerTypeInput && this.$refs.providerTypeInput.$el) {
        this.providerTypeInputWidth = this.$refs.providerTypeInput.$el.getBoundingClientRect().width
      }
    })
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },

  methods: {
    setFallbackImage,
    handleResize() {
      if (this.$refs.providerTypeInput && this.$refs.providerTypeInput.$el) {
        this.providerTypeInputWidth = this.$refs.providerTypeInput.$el.getBoundingClientRect().width
      }
      this.resetProviderListInputHeight()
    },
    setProviderTypeState(onOff) {
      this.showProviderTypeSelection = onOff
    },
    tagForProvider(provider) {
      let tag = provider.description
      if (provider.serviceTypes.length) {
        let counter = 0
        for (const service of provider.serviceTypes) {
          const index = this.filterServices.findIndex(aService => {
            return aService.id === service.id
          })
          if (index !== -1) {
            counter++
          }
        }
        if (counter === provider.serviceTypes.length || counter === 0) {
          tag += ` (${this.$t('marketplace.all-services')})`
        } else {
          tag += ` (${this.$t('marketplace.selected-services')})`
        }
      }
      return tag
    },
    resetProviderListInputHeight() {
      this.$nextTick(() => {
        if (this.$refs.providerTypeInput && this.$refs.providerTypeInput.$el) {
          const inputChildNodes = this.$refs.providerTypeInput.$el.childNodes
          const input = [].filter.call(inputChildNodes, item => item.tagName === 'INPUT')[0]
          const tags = this.$refs.providerTypeTags
          const sizeInMap = 40 // Height of the input element
          input.style.height =
            this.filterProviders.length === 0
              ? `${sizeInMap}px`
              : `${Math.max(tags ? tags.clientHeight + (tags.clientHeight > sizeInMap ? 6 : 0) : 0, sizeInMap)}px`
        }
      })
    },
    providerListCheckChanged(_clickedNode, allNodes) {
      const topLevelSelected = []
      const secondLevelSelected = []
      for (const providerType of this.providerList) {
        if (allNodes.halfCheckedKeys.includes(providerType.nodeId) || allNodes.checkedKeys.includes(providerType.nodeId)) {
          topLevelSelected.push(providerType)
        }
        // We only want the service types listed for ones where only some of the child ones are listed. In this
        // case, the parent would be half checked
        if (allNodes.halfCheckedKeys.includes(providerType.nodeId)) {
          for (const serviceType of providerType.serviceTypes) {
            if (allNodes.checkedKeys.includes(serviceType.nodeId)) {
              secondLevelSelected.push(serviceType)
            }
          }
        }
      }
      this.filterProviders = topLevelSelected
      this.filterServices = secondLevelSelected
    },
    loadInitialSearch() {
      const searchData = JSON.parse(localStorage.getItem(`_mpSearch_${sdk.instance.credentials.personUid}`))
      if (!searchData) return
      if (searchData.filterText) this.filterText = searchData.filterText
      if (searchData.filterSpeed) this.filterSpeed = searchData.filterSpeed
      if (searchData.filterProviders) this.filterProviders = searchData.filterProviders
      if (searchData.filterServices) this.filterServices = searchData.filterServices
      if (searchData.filterCountry) this.filterCountry = searchData.filterCountry
      if (searchData.sortOrder) this.sortOrder = searchData.sortOrder
      if (searchData.viewType) this.viewType = searchData.viewType
    },
    saveSearchParams() {
      const searchData = {
        filterText: this.filterText,
        filterSpeed: this.filterSpeed,
        filterProviders: this.filterProviders,
        filterServices: this.filterServices,
        filterCountry: this.filterCountry,
        sortOrder: this.sortOrder,
        viewType: this.viewType,
      }
      localStorage.setItem(`_mpSearch_${sdk.instance.credentials.personUid}`, JSON.stringify(searchData))
    },
    clearProviderTypeFilter() {
      this.$refs.providerListTree.setCheckedNodes([])
      this.filterServices = []
      this.filterProviders = []
    },
  },
}
</script>

<style lang="scss" scoped>
.grid-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 992px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: 1200px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (min-width: 1920px) {
    grid-template-columns: repeat(6, 1fr);
  }
}

.filter-column {
  padding: 0 1rem 0 0;
  box-sizing: border-box;
  display: flex;
  width: 50%;
  min-width: 470px;
}
.filter-column > div {
  flex: 1 1 auto;
}

.width-175px {
  width: 175px;
}

.empty-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(90dvh - 500px);

  .fas {
    font-size: 4rem;
    color: var(--color-text-regular);
  }
}
</style>
